import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Typography,
} from '@material-ui/core'
import { orderBy, uniq } from 'lodash'

import { ChevronDown as ChevronDownIcon } from 'react-feather'

import { CheckboxItem } from './components'

import constants from 'constants/index'

import useStyles from './styles'

const LegalFrameworksChecklist = ({
  activePersonalDataType,
  activePersonalSensitiveDataType,
  activeMinorDataType,
  predictions,
  legalFrameworks,
  selectedLegalFrameworks,
  setSelectedLegalFrameworks,
}) => {
  const [shouldRenderCheckbox, setShouldRenderCheckbox] = useState(false)

  useEffect(() => {
    if (shouldRenderCheckbox) {
      setShouldRenderCheckbox(false)
    }
  }, [shouldRenderCheckbox])

  const activeMinorSensitiveDataType =
    activeMinorDataType && activePersonalSensitiveDataType
  const classes = useStyles()

  const handleSelectAll = (items) => {
    setSelectedLegalFrameworks(
      uniq([...selectedLegalFrameworks, ...items?.map((item) => item?.id)]),
    )
  }

  const handleCheckItem = (id, name) => {
    const itemExists = !!selectedLegalFrameworks?.find((item) => item === id)

    const ids = [id]

    if (name === constants.legalFramework.CONSENT) {
      for (const dataType of constants.legalFramework.DATA_TYPES_MINOR_VALUES) {
        if (
          (dataType === constants.legalFramework.TYPE_MINOR_VALUE &&
            activeMinorDataType) ||
          (dataType === constants.legalFramework.TYPE_MINOR_SENSITIVE_VALUE &&
            activeMinorSensitiveDataType)
        ) {
          const idConsent = legalFrameworks?.[dataType]?.items?.find(
            (item) => item?.name === constants.legalFramework.PARENTAL_CONSENT,
          )?.id
          if (idConsent) {
            ids.push(idConsent)
          }
        }
      }
    }

    if (itemExists) {
      setSelectedLegalFrameworks(
        selectedLegalFrameworks?.filter((item) => !ids.includes(item)),
      )
    } else {
      setSelectedLegalFrameworks([...selectedLegalFrameworks, ...ids])
    }

    setShouldRenderCheckbox(ids.length > 1)
  }

  // O modelo de concentimento dos pais não está pronto
  const definePrediction = (item) =>
    predictions?.find(
      (prediction) =>
        prediction.name === item?.name?.toLowerCase() &&
        prediction.name !== 'consentimento dos pais',
    )?.prediction

  const disableCheckBox = (legalFramework) => {
    return {
      1: !activePersonalDataType,
      2: !activePersonalSensitiveDataType,
      3: !activeMinorDataType,
      4: !activeMinorSensitiveDataType,
    }[legalFramework.dataType]
  }

  return (
    <>
      {orderBy(legalFrameworks, 'order')?.map((legalFramework) => (
        <Accordion
          key={legalFramework?.label}
          variant="outlined"
          defaultExpanded={!disableCheckBox(legalFramework?.items?.[0])}
        >
          <AccordionSummary expandIcon={<ChevronDownIcon />}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={2.5}
              width="100%"
            >
              <Typography variant="body1" fontWeight="bold">
                {legalFramework?.label}
              </Typography>
              <Button
                variant="text"
                color="primary"
                className={classes.buttonSelectAll}
                onClick={() => handleSelectAll(legalFramework?.items)}
              >
                Selecionar todos
              </Button>
            </Box>
          </AccordionSummary>
          <Divider />
          <Box ml={2}>
            {orderBy(legalFramework?.items, 'id')?.map((legalFrameworkItem) => (
              <CheckboxItem
                key={legalFrameworkItem?.id}
                prediction={definePrediction(legalFrameworkItem)}
                item={legalFrameworkItem}
                defaultChecked={selectedLegalFrameworks.includes(
                  legalFrameworkItem?.id,
                )}
                handleCheck={handleCheckItem}
                classes={classes}
                disabled={disableCheckBox(legalFrameworkItem)}
                shouldRender={
                  shouldRenderCheckbox &&
                  legalFrameworkItem.name ===
                    constants.legalFramework.PARENTAL_CONSENT
                }
              />
            ))}
          </Box>
        </Accordion>
      ))}
    </>
  )
}

LegalFrameworksChecklist.propTypes = {
  predictions: PropTypes.array,
  legalFrameworks: PropTypes.object,
  selectedLegalFrameworks: PropTypes.array,
  setSelectedLegalFrameworks: PropTypes.func,
}

LegalFrameworksChecklist.defaultProps = {
  predictions: [],
  legalFrameworks: [],
  selectedLegalFrameworks: [],
  setSelectedLegalFrameworks: () => {},
}

export default LegalFrameworksChecklist
