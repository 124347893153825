import { include } from 'named-urls'
import { Switch, Redirect, Route } from 'react-router-dom'
import { RouteWithLayout } from './components'
import { CompanyProvider, RmcsListProvider } from 'providers'
import { Auth as AuthLayout, Main as MainLayout } from './layouts'
import { DataProcessProvider, RmcProvider } from 'providers'

import {
  CollaboratorProfiles,
  Collaborators,
  Companies,
  CompanyAudits,
  CompanySizes,
  Coupons,
  DataProcesses,
  Departments,
  Documents,
  DocumentationLinks,
  EconomicActivities,
  Forbidden,
  ForgotPassword,
  Groups,
  Incidents,
  IntegrationPartner,
  IntegrationPlans,
  LegalFrameworks,
  Login,
  NewPassword,
  NoPermissionsFound,
  NotFound,
  Plans,
  PreRegistrations,
  PrivacyPolicies,
  Questionnaires,
  Reports,
  Rmcs,
  Segments,
  Subgroups,
  Suppliers,
  UserProfiles,
  Users,
  lastUpdates,
  Faqs,
  Documentations,
  TechnicalFaqs,
  Contracts,
  PaymentOrders,
  ContractInvoices,
  Sublicensings,
  Sellers,
  Churns,
  FinancialDashboard,
} from './views'
import constants from 'constants/index'

import permissions from 'constants/permissions'
import LastUpdates from 'views/LastUpdates'

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        path={routes.root}
        layout={AuthLayout}
        component={Login}
        animatedLoading
        exact
      />
      <RouteWithLayout
        path={routes.forgotPassword}
        component={ForgotPassword}
        layout={AuthLayout}
        animatedLoading
        exact
      />
      <RouteWithLayout
        path={routes.newPassword}
        component={NewPassword}
        layout={AuthLayout}
        animatedLoading
        exact
      />
      <RouteWithLayout
        path={routes.dataProcess.all}
        component={DataProcesses.Main}
        provider={DataProcessProvider}
        layout={MainLayout}
        permission={constants.permissions.DATA_PROCESSES.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.dataProcess.new}
        component={DataProcesses.New}
        provider={DataProcessProvider}
        layout={MainLayout}
        permission={constants.permissions.DATA_PROCESSES.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.dataProcess.view}
        component={DataProcesses.View}
        provider={DataProcessProvider}
        layout={MainLayout}
        permission={constants.permissions.DATA_PROCESSES.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.dataProcess.edit}
        component={DataProcesses.Edit}
        provider={DataProcessProvider}
        layout={MainLayout}
        permission={constants.permissions.DATA_PROCESSES.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.dataProcess.copy}
        component={DataProcesses.Copy}
        layout={MainLayout}
        permission={constants.permissions.DATA_PROCESSES.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.legalFrameworks}
        component={LegalFrameworks.Main}
        layout={MainLayout}
        somePermissions={[
          constants.permissions.DATA_PROCESSES.REVIEW,
          constants.permissions.DATA_PROCESSES.MANAGE_LEGAL_FRAMEWORKS,
        ]}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companies.all}
        component={Companies.Main}
        layout={MainLayout}
        permission={permissions.COMPANIES.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companies.easy}
        component={Companies.Main}
        layout={MainLayout}
        permission={permissions.COMPANIES.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companies.new}
        component={Companies.New}
        provider={CompanyProvider}
        layout={MainLayout}
        permission={permissions.COMPANIES.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.departments.all}
        component={Departments.Main}
        layout={MainLayout}
        permission={permissions.DEPARTMENT.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.departments.new}
        component={Departments.New}
        layout={MainLayout}
        permission={permissions.DEPARTMENT.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.departments.edit}
        component={Departments.Edit}
        layout={MainLayout}
        permission={permissions.DEPARTMENT.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.incidents.all}
        component={Incidents.Main}
        layout={MainLayout}
        permission={permissions.INCIDENTS.LIST}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.incidents.show}
        component={Incidents.Show}
        layout={MainLayout}
        permission={permissions.INCIDENTS.LIST}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companies.edit}
        component={Companies.Edit}
        provider={CompanyProvider}
        layout={MainLayout}
        somePermissions={[
          permissions.COMPANIES.UPDATE,
          permissions.COMPANIES.SHOW,
        ]}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.users.all}
        component={Users.Main}
        layout={MainLayout}
        permission={permissions.USERS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.users.new}
        component={Users.New}
        layout={MainLayout}
        permission={permissions.USERS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.users.edit}
        component={Users.Edit}
        layout={MainLayout}
        permission={permissions.USERS.EDIT}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.collaborators.all}
        component={Collaborators.Main}
        layout={MainLayout}
        permission={permissions.COLLABORATORS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.collaborators.new}
        component={Collaborators.New}
        layout={MainLayout}
        permission={permissions.COLLABORATORS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.collaborators.edit}
        component={Collaborators.Edit}
        layout={MainLayout}
        permission={permissions.COLLABORATORS.EDIT}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.userProfiles.all}
        component={UserProfiles.Main}
        layout={MainLayout}
        permission={permissions.CLIENT_PROFILES.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.userProfiles.new}
        component={UserProfiles.New}
        layout={MainLayout}
        permission={permissions.CLIENT_PROFILES.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.userProfiles.edit}
        component={UserProfiles.Edit}
        layout={MainLayout}
        permission={permissions.CLIENT_PROFILES.EDIT}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.collaboratorProfiles.all}
        component={CollaboratorProfiles.Main}
        layout={MainLayout}
        permission={permissions.COLLABORATOR_PROFILES.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.collaboratorProfiles.new}
        component={CollaboratorProfiles.New}
        layout={MainLayout}
        permission={permissions.COLLABORATOR_PROFILES.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.collaboratorProfiles.edit}
        component={CollaboratorProfiles.Edit}
        layout={MainLayout}
        permission={permissions.COLLABORATOR_PROFILES.EDIT}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.nonComplianceReport.all}
        component={Rmcs.Main}
        provider={RmcsListProvider}
        layout={MainLayout}
        somePermissions={[
          constants.permissions.NON_COMPLIANCE_REPORT.RESUME,
          constants.permissions.NON_COMPLIANCE_REPORT.LIST,
        ]}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.nonComplianceReport.show}
        component={Rmcs.View}
        provider={RmcProvider}
        layout={MainLayout}
        permission={constants.permissions.NON_COMPLIANCE_REPORT.SHOW}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.plans.all}
        component={Plans.Main}
        layout={MainLayout}
        permission={permissions.PLANS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.plans.new}
        component={Plans.New}
        layout={MainLayout}
        permission={permissions.PLANS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.plans.edit}
        component={Plans.Edit}
        layout={MainLayout}
        permission={permissions.PLANS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.coupons.all}
        component={Coupons.Main}
        layout={MainLayout}
        permission={constants.permissions.COUPONS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.coupons.new}
        component={Coupons.New}
        layout={MainLayout}
        permission={constants.permissions.COUPONS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.coupons.edit}
        component={Coupons.Edit}
        layout={MainLayout}
        permission={constants.permissions.COUPONS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.preRegistrations.all}
        component={PreRegistrations.Main}
        layout={MainLayout}
        permission={constants.permissions.PRE_REGISTRATIONS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.preRegistrations.show}
        component={PreRegistrations.Show}
        layout={MainLayout}
        permission={constants.permissions.PRE_REGISTRATIONS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.preRegistrations.new}
        component={PreRegistrations.New}
        layout={MainLayout}
        permission={constants.permissions.PRE_REGISTRATIONS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.preRegistrations.edit}
        component={PreRegistrations.Edit}
        layout={MainLayout}
        permission={constants.permissions.PRE_REGISTRATIONS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.contracts.all}
        component={Contracts.Main}
        layout={MainLayout}
        permission={constants.permissions.PAYMENT_CONTRACTS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.contracts.new}
        component={Contracts.New}
        layout={MainLayout}
        permission={constants.permissions.PAYMENT_CONTRACTS.MANAGE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.contracts.edit}
        component={Contracts.Edit}
        layout={MainLayout}
        permission={constants.permissions.PAYMENT_CONTRACTS.MANAGE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.contracts.show}
        component={Contracts.Show}
        layout={MainLayout}
        permission={constants.permissions.PAYMENT_CONTRACTS.SHOW}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.sublicensings.all}
        component={Sublicensings.Main}
        layout={MainLayout}
        permission={constants.permissions.PRE_REGISTRATIONS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.paymentOrders.all}
        component={PaymentOrders.Main}
        layout={MainLayout}
        permission={constants.permissions.PAYMENT_ORDERS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.paymentOrders.show}
        component={PaymentOrders.Show}
        layout={MainLayout}
        permission={constants.permissions.PAYMENT_ORDERS.MANAGE_PAYMENT}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.contractInvoices.all}
        component={ContractInvoices.Main}
        layout={MainLayout}
        permission={constants.permissions.PAYMENT_CONTRACTS.INVOICE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companyAudits.all}
        component={CompanyAudits.Main}
        layout={MainLayout}
        permission={constants.permissions.DATA_PROCESSES.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.groups.all}
        component={Groups.Main}
        layout={MainLayout}
        permission={permissions.GROUPS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.segments.all}
        component={Segments.Main}
        layout={MainLayout}
        permission={permissions.SEGMENTS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.subgroups.all}
        component={Subgroups.Main}
        layout={MainLayout}
        permission={constants.permissions.SUBGROUPS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.segments.new}
        component={Segments.New}
        layout={MainLayout}
        provider={CompanyProvider}
        permission={permissions.SEGMENTS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.groups.new}
        component={Groups.New}
        layout={MainLayout}
        permission={permissions.GROUPS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.subgroups.new}
        component={Subgroups.New}
        layout={MainLayout}
        permission={constants.permissions.SUBGROUPS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.groups.edit}
        component={Groups.Edit}
        layout={MainLayout}
        permission={permissions.GROUPS.EDIT}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.subgroups.edit}
        component={Subgroups.Edit}
        layout={MainLayout}
        permission={constants.permissions.SUBGROUPS.EDIT}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.segments.edit}
        component={Segments.Edit}
        layout={MainLayout}
        provider={CompanyProvider}
        permission={permissions.SEGMENTS.EDIT}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.privacyPolicy.all}
        component={PrivacyPolicies.Main}
        layout={MainLayout}
        permission={permissions.PRIVACY_POLICY.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.documentationLinks.all}
        component={DocumentationLinks.Main}
        layout={MainLayout}
        permission={permissions.DOCUMENTATION_LINKS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.documentationLinks.new}
        component={DocumentationLinks.New}
        layout={MainLayout}
        permission={permissions.DOCUMENTATION_LINKS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.documentationLinks.edit}
        component={DocumentationLinks.Edit}
        layout={MainLayout}
        permission={permissions.DOCUMENTATION_LINKS.EDIT}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companySizes.all}
        component={CompanySizes.Main}
        layout={MainLayout}
        permission={permissions.COMPANY_SIZES.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companySizes.new}
        component={CompanySizes.New}
        layout={MainLayout}
        permission={permissions.COMPANY_SIZES.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companySizes.edit}
        component={CompanySizes.Edit}
        layout={MainLayout}
        permission={permissions.COMPANY_SIZES.EDIT}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.economicActivities.all}
        component={EconomicActivities.Main}
        layout={MainLayout}
        permission={permissions.ECONOMIC_ACTIVITIES.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.economicActivities.new}
        component={EconomicActivities.New}
        layout={MainLayout}
        permission={permissions.ECONOMIC_ACTIVITIES.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.economicActivities.edit}
        component={EconomicActivities.Edit}
        layout={MainLayout}
        permission={permissions.ECONOMIC_ACTIVITIES.EDIT}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.privacyPolicy.show}
        component={PrivacyPolicies.Show}
        layout={MainLayout}
        permission={permissions.PRIVACY_POLICY.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.reports.main}
        component={Reports.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionnaires.all}
        component={Questionnaires.Main}
        layout={MainLayout}
        permission={permissions.QUESTIONNAIRE_BY_COMPANY.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionnaires.edit}
        component={Questionnaires.Edit}
        layout={MainLayout}
        permission={permissions.QUESTIONNAIRE_BY_COMPANY.EDIT}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionnaires.show}
        component={Questionnaires.Show}
        layout={MainLayout}
        permission={permissions.QUESTIONNAIRE_BY_COMPANY.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.suppliers.all}
        component={Suppliers.Main}
        layout={MainLayout}
        permission={permissions.COMPANY_SUPPLIERS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.suppliers.new}
        component={Suppliers.New}
        layout={MainLayout}
        permission={permissions.COMPANY_SUPPLIERS.UPDATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.suppliers.show}
        component={Suppliers.Show}
        layout={MainLayout}
        permission={permissions.COMPANY_SUPPLIERS.SHOW}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.documentsTemplate}
        component={Documents.DocumentsMain}
        layout={MainLayout}
        permission={permissions.DOCUMENTS_TEMPLATE.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.lastUpdates.all}
        component={LastUpdates.Main}
        layout={MainLayout}
        permission={permissions.LAST_UPDATES.SHOW}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.lastUpdates.view}
        component={lastUpdates.Show}
        layout={MainLayout}
        permission={permissions.LAST_UPDATES.LIST}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.lastUpdates.new}
        component={lastUpdates.New}
        layout={MainLayout}
        permission={permissions.LAST_UPDATES.CREATE}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.lastUpdates.edit}
        component={lastUpdates.Edit}
        layout={MainLayout}
        permission={permissions.LAST_UPDATES.EDIT}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.faqs.all}
        component={Faqs.Main}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.faqs.new}
        component={Faqs.New}
        layout={MainLayout}
        permission={permissions.FAQS_DOCUMENTATIONS.CREATE}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.faqs.edit}
        component={Faqs.Edit}
        layout={MainLayout}
        permission={permissions.FAQS_DOCUMENTATIONS.EDIT}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.documentations.all}
        component={Documentations.Main}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.documentations.new}
        component={Documentations.New}
        layout={MainLayout}
        permission={permissions.FAQS_DOCUMENTATIONS.CREATE}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.documentations.edit}
        component={Documentations.Edit}
        layout={MainLayout}
        permission={permissions.FAQS_DOCUMENTATIONS.EDIT}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.new}
        component={TechnicalFaqs.New}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.edit}
        component={TechnicalFaqs.Edit}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.knowledgeBase}
        component={TechnicalFaqs.KnowledgeBaseMain}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.subcategory}
        component={TechnicalFaqs.SubcategoryMain}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.newSubcategory}
        component={TechnicalFaqs.SubcategoryNew}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.editSubcategory}
        component={TechnicalFaqs.SubcategoryEdit}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.sellers.all}
        component={Sellers.Main}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.sellers.new}
        component={Sellers.New}
        layout={MainLayout}
        permission={permissions.SELLERS.CREATE}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.sellers.edit}
        component={Sellers.Edit}
        layout={MainLayout}
        permission={permissions.SELLERS.CREATE}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.integrationPartner.all}
        component={IntegrationPartner.Main}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.integrationPartner.new}
        component={IntegrationPartner.New}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.integrationPartner.edit}
        component={IntegrationPartner.Edit}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.integrationPlans.all}
        component={IntegrationPlans.Main}
        layout={MainLayout}
        permission={permissions.PLANS.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.integrationPlans.new}
        component={IntegrationPlans.New}
        layout={MainLayout}
        permission={permissions.PLANS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.integrationPlans.edit}
        component={IntegrationPlans.Edit}
        layout={MainLayout}
        permission={permissions.PLANS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.churns.all}
        component={Churns.Main}
        layout={MainLayout}
        permission={permissions.CHURNS.LIST}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.churns.edit}
        component={Churns.Edit}
        layout={MainLayout}
        permission={permissions.CHURNS.MANAGE}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.churns.show}
        component={Churns.Show}
        layout={MainLayout}
        permission={permissions.CHURNS.SHOW}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.financialDashboard.all}
        component={FinancialDashboard.Main}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />

      <Route component={NotFound} exact path={routes.notFound} />
      <Route component={Forbidden} exact path={routes.forbidden} />
      <Route component={NoPermissionsFound} exact path={routes.noPermissions} />
      <Redirect to={routes.notFound} />
    </Switch>
  )
}

export const routes = {
  root: '/',

  dataProcess: include('/data-processes', {
    all: '',
    new: 'new',
    copy: 'copy',
    view: ':dataProcessId/show',
    edit: ':dataProcessId/edit',
  }),

  legalFrameworks: '/legal-frameworks',

  forgotPassword: '/forgot',

  newPassword: '/new-password/:reset_password_token?',

  home: '/home',

  documentsTemplate: '/documents-template',

  companies: include('/companies', {
    all: '',
    easy: 'lite',
    new: 'new',
    edit: 'edit/:companyId',
  }),

  suppliers: include('/suppliers', {
    all: '',
    new: 'new',
    show: ':companySupplierId/show',
  }),

  departments: include('/departments', {
    all: '',
    new: 'new',
    edit: 'edit/:departmentId',
  }),

  notFound: '/not-found',
  forbidden: '/forbidden',
  noPermissions: '/no-permissions',

  incidents: include('/incidents', {
    all: '',
    show: 'show/:incidentId',
  }),

  users: include('/users', {
    all: '',
    new: 'new',
    edit: 'edit/:userId',
  }),

  collaborators: include('/collaborators', {
    all: '',
    new: 'new',
    edit: 'edit/:userId',
  }),

  userProfiles: include('/user-profiles', {
    all: '',
    new: 'new',
    edit: 'edit/:userProfileId',
  }),

  collaboratorProfiles: include('/collaborator-profiles', {
    all: '',
    new: 'new',
    edit: 'edit/:collaboratorProfileId',
  }),
  nonComplianceReport: include('/non-compliance-reports', {
    all: '',
    show: ':nonComplianceReportId/show',
  }),
  plans: include('/plans', {
    all: '',
    new: 'new',
    edit: 'edit/:planId',
  }),

  coupons: include('/coupons', {
    all: '',
    new: 'new',
    edit: 'edit/:couponId',
  }),

  preRegistrations: include('/pre-registrations', {
    all: '',
    show: 'show/:preRegistrationToken',
    new: 'new',
    edit: 'edit/:preRegistrationToken',
  }),

  sublicensings: include('/sublicensings', {
    all: '',
  }),

  contracts: include('/contracts', {
    all: '',
    new: 'new',
    edit: 'edit/:contractId',
    show: 'show/:contractId',
  }),

  paymentOrders: include('/payment-orders', {
    all: '',
    show: 'show/:paymentOrderId',
  }),

  contractInvoices: include('/contract-invoices', {
    all: '',
  }),

  companyAudits: include('/company-audits', {
    all: '',
  }),

  groups: include('/groups', {
    all: '',
    new: 'new',
    edit: 'edit/:groupId',
  }),

  subgroups: include('/subgroups', {
    all: '',
    new: 'new',
    edit: 'edit/:subgroupId',
  }),

  segments: include('/segments', {
    all: '',
    new: 'new',
    edit: 'edit/:segmentId',
  }),

  privacyPolicy: include('/privacy-policy', {
    all: '',
    show: 'show/:privacyPolicyId',
  }),

  documentationLinks: include('/documentation-links', {
    all: '',
    new: 'new',
    edit: 'edit/:documentationLinkId',
  }),

  companySizes: include('/company-sizes', {
    all: '',
    new: 'new',
    edit: 'edit/:sizeId',
  }),

  economicActivities: include('/economic-activities', {
    all: '',
    new: 'new',
    edit: 'edit/:activityId',
  }),

  reports: include('/reports', {
    main: ':report',
  }),

  questionnaires: include('/questionnaires', {
    all: '',
    edit: 'edit/:questionnaireId',
    show: 'show/:questionnaireId',
  }),

  lastUpdates: include('/last-updates', {
    all: '',
    new: 'new',
    view: 'show/:lastUpdateId',
    edit: 'edit/:lastUpdateId',
  }),

  faqs: include('/faqs', {
    all: '',
    new: 'new',
    view: 'show/:faqsDocumentationId',
    edit: 'edit/:faqsDocumentationId',
  }),

  documentations: include('/documentations', {
    all: '',
    new: 'new',
    view: 'show/:faqsDocumentationId',
    edit: 'edit/:faqsDocumentationId',
  }),

  technicalFaqs: include('/technical-faqs', {
    all: '',
    new: 'new',
    edit: 'edit/:technicalFaqId',
    knowledgeBase: 'knowledge-base',
    subcategory: 'subcategory',
    newSubcategory: 'subcategory/new',
    editSubcategory: 'subcategory/edit/:subCategoryId',
  }),
  sellers: include('/sellers', {
    all: '',
    new: 'new',
    edit: 'edit/:sellerId',
  }),
  integrationPartner: include('/integration-partners', {
    all: '',
    new: 'new',
    view: 'show/:integrationPartnerId',
    edit: 'edit/:integrationPartnerId',
  }),
  integrationPlans: include('/integration-plans', {
    all: '',
    new: 'new',
    view: 'show/:planId',
    edit: 'edit/:planId',
  }),
  churns: include('/churns', {
    all: '',
    show: 'show/:churnId',
    edit: 'edit/:churnId',
  }),
  financialDashboard: include('/financial-dashboard', {
    all: '',
  }),
}

export default Routes
