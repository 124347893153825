import constants from 'constants/index'

const legalFrameworkValueByDataType = (dataType) => {
  switch (dataType) {
    case constants.legalFramework.TYPE_PERSONAL_AND_FINANCIAL:
      return constants.legalFramework.TYPE_PERSONAL_AND_FINANCIAL_VALUE

    case constants.legalFramework.TYPE_SENSITIVE:
      return constants.legalFramework.TYPE_SENSITIVE_VALUE

    case constants.legalFramework.TYPE_MINOR:
      return constants.legalFramework.TYPE_MINOR_VALUE

    case constants.legalFramework.TYPE_MINOR_SENSITIVE:
      return constants.legalFramework.TYPE_MINOR_SENSITIVE_VALUE

    default:
      return ''
  }
}

export default legalFrameworkValueByDataType
