import { useEffect, useState } from 'react'
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import useStyles from './styles'

const CheckboxItem = ({
  disabled,
  item,
  defaultChecked,
  handleCheck,
  prediction,
  classes,
  shouldRender,
}) => {
  const [checked, setChecked] = useState(defaultChecked)
  const classesCheckbox = useStyles()

  const handleCheckItem = () => {
    handleCheck(item?.id, item?.name)
    setChecked((value) => !value)
  }

  useEffect(() => {
    if (shouldRender && checked !== defaultChecked) {
      setChecked(defaultChecked)
    }
    //eslint-disable-next-line
  }, [shouldRender])

  useEffect(() => {
    if (!disabled && prediction > 0.7) {
      handleCheck(item?.id)
      setChecked(true)
    }
    //eslint-disable-next-line
  }, [prediction])

  useEffect(() => {
    if (defaultChecked) setChecked(true)
  }, [defaultChecked])

  const formatedPrediction =
    !!prediction && !disabled
      ? (prediction * 100).toFixed(0).toString().padStart(2, '0') + '%'
      : '-'

  return (
    <Box className={classes.legalFrameworkRoot}>
      <Box
        mr={2}
        width="28px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography>{formatedPrediction}</Typography>
      </Box>
      <FormControlLabel
        className={clsx({
          [classesCheckbox.labelDisabled]: disabled && !checked,
        })}
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheckItem}
            name={item?.name}
            classes={{ root: classes.checkboxRoot }}
            color="primary"
            className={clsx(classesCheckbox.checkbox, {
              [classesCheckbox.checkboxDisabled]: disabled && !checked,
            })}
          />
        }
        label={item?.name}
      />
    </Box>
  )
}

CheckboxItem.propTypes = {
  item: PropTypes.object,
  defaultChecked: PropTypes.bool,
  handleCheck: PropTypes.func,
  prediction: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.any,
}

CheckboxItem.defaultProps = {
  item: {},
  defaultChecked: false,
  handleCheck: () => {},
}

export default CheckboxItem
