const TYPE_PERSONAL_AND_FINANCIAL = 1
const TYPE_SENSITIVE = 2
const TYPE_MINOR = 3
const TYPE_MINOR_SENSITIVE = 4

const TYPE_PERSONAL_AND_FINANCIAL_VALUE = 'personalAndFinancial'
const TYPE_SENSITIVE_VALUE = 'sensitive'
const TYPE_MINOR_VALUE = 'minor'
const TYPE_MINOR_SENSITIVE_VALUE = 'minorSensitive'

const TYPE_PERSONAL_AND_FINANCIAL_LABEL = 'Pessoais'
const TYPE_SENSITIVE_LABEL = 'Pessoais sensíveis'
const TYPE_MINOR_LABEL = 'Crianças e/ou adolescentes pessoais'
const TYPE_MINOR_SENSITIVE_LABEL = 'Crianças e/ou adolescentes sensíveis'

const CONSENT = 'Consentimento'
const PARENTAL_CONSENT = 'Consentimento dos pais'

const DATA_TYPES_MINOR_VALUES = [TYPE_MINOR_VALUE, TYPE_MINOR_SENSITIVE_VALUE]

const legalFramework = {
  CONSENT,
  PARENTAL_CONSENT,
  TYPE_MINOR_LABEL,
  TYPE_MINOR_VALUE,
  TYPE_MINOR_SENSITIVE_VALUE,
  TYPE_MINOR,
  TYPE_MINOR_SENSITIVE,
  TYPE_MINOR_SENSITIVE_LABEL,
  DATA_TYPES_MINOR_VALUES,
  TYPE_PERSONAL_AND_FINANCIAL_LABEL,
  TYPE_PERSONAL_AND_FINANCIAL_VALUE,
  TYPE_PERSONAL_AND_FINANCIAL,
  TYPE_SENSITIVE_LABEL,
  TYPE_SENSITIVE_VALUE,
  TYPE_SENSITIVE,
}

export default legalFramework
