const COMPANIES = {
  LIST: 'list_companies',
  SHOW: 'show_company',
  CREATE: 'create_company',
  UPDATE: 'edit_company',
  DELETE: 'destroy_company',
  LIST_COMPANY_STEPS: 'list_companies_steps',
  EXPORT_LIST: 'list_companies_steps',
  INACTIVE_AND_ACTIVE: 'change_company_status',
  UPDATE_SELF: 'update_self_company',
  SHOW_LOGS: 'logs_company',
  EXPORT_DATA_PROCESSES: 'export_processes',
  UPDATE_DEMO_PARTNER: 'change_demo_partner',
}

const COMPANY_SUPPLIERS = {
  LIST: 'index_company_suppliers',
  SHOW: 'show_company_supplier',
  UPDATE: 'update_company_supplier',
}

const INCIDENTS = {
  LIST: 'list_incidents',
  CREATE: 'create_incident',
  INACTIVATE: 'inactivate_incident',
}

const DATA_PROCESSES = {
  LIST: 'list_data_processes',
  CREATE: 'create_data_process',
  INACTIVE_AND_REACTIVE: 'inactive_and_reactive_data_process',
  DESTROY: 'destroy_data_process',
  REVIEW: 'review_data_process',
  REOPEN: 'reopen_data_process',
  LIST_AND_MANAGE_SUGGESTED: 'list_and_manage_suggested_data_processes',
  MANAGE_LEGAL_FRAMEWORKS: 'index_with_legal_frameworks',
}

const NON_COMPLIANCE_REPORT = {
  LIST: 'list_non_compliance_reports',
  RESUME: 'resume_non_compliance_report',
  EDIT: 'edit_non_compliance_report',
  HANDLE_ACTIONS: 'handle_non_compliance_reports_actions',
  HANDLE_SPECIFIC_RISKS: 'handle_specific_risks',
  LIST_DATA_PROCESSES: 'list_all_non_compliance_report_data_process',
  LIST_LINKED_DATA_PROCESS: 'list_linked_non_compliance_report_data_process',
  LIST_QUESTIONNAIRE: 'list_all_non_compliance_report_by_questionnaire',
  LIST_LINKED_QUESTIONNAIRE:
    'list_linked_non_compliance_report_by_questionnaire',
  LIST_INCIDENT: 'list_incident_non_compliance_reports',
  LIST_RESPONSIBLE: 'list_responsable_non_compliance_reports',
}

const USERS = {
  SHOW: 'show_user',
  LIST: 'list_users',
  CREATE: 'create_user',
  EDIT: 'edit_user',
  DESTROY: 'destroy_user',
  CHANGE_STATUS: 'change_user_status',
  EXPORT: 'export_users',
  TEMP_PASSOWRD: 'generate_user_temporary_password',
}

const COLLABORATORS = {
  SHOW: 'show_user_collaborator',
  LIST: 'list_user_collaborators',
  CREATE: 'create_user_collaborator',
  EDIT: 'edit_user_collaborator',
  CHANGE_STATUS: 'change_user_collaborator_status',
  EXPORT: 'export_user_collaborators',
}

const CLIENT_PROFILES = {
  LIST: 'list_client_profiles',
  CREATE: 'create_client_profile',
  EDIT: 'edit_client_profile',
  DESTROY: 'destroy_client_profile',
  CHANGE_STATUS: 'change_status_client_profile',
}

const COLLABORATOR_PROFILES = {
  LIST: 'list_collaborator_profiles',
  CREATE: 'create_collaborator_profile',
  EDIT: 'edit_collaborator_profile',
  DESTROY: 'destroy_collaborator_profile',
  CHANGE_STATUS: 'change_status_collaborator_profile',
}

const COUPONS = {
  LIST: 'list_discount_coupons',
  CREATE: 'create_discount_coupon',
  DESTROY: 'destroy_discount_coupon',
  SHOW_LOGS: 'show_discount_coupon_logs',
}

const DEPARTMENT = {
  LIST: 'list_departments',
  CREATE: 'create_department',
  LINK_USERS: 'link_users_to_department',
  ACTIVATE_OR_INACTIVATE: 'activate_or_inactivate_department',
}

const QUESTIONNAIRES = {
  REPLY: 'reply_questionnaire',
  LINK_USERS: 'link_users',
}

const RIPD = {
  EXPORT: 'export_ripd',
  EXPORT_ADMIN: 'export_ripd_admin',
}

const DASHBOARD = {
  VIEW: 'view_dashboard',
  VIEW_PROCESS: 'view_dashboard_process',
  VIEW_RMC: 'view_dashboard_rnc',
  VIEW_QUESTIONNAIRE: 'view_dashboard_gap',
}

const EXPORT_INFO = {
  EXPORT_RIPD: 'export_ripd',
}

const EXPORT_RIPD = {
  EXPORT_RIPD_ADMIN: 'export_ripd_admin',
}

const PRIVACY_POLICY = {
  LIST: 'list_privacy_policy',
  CREATE: 'create_privacy_policy',
  STAMP: 'stamp_privacy_policy',
}

const QUESTIONNAIRE_BY_COMPANY = {
  LIST: 'list_all_questionnaires',
  CREATE: 'create_questionnaire',
  EDIT: 'update_questionnaire',
}

const QUESTION = {
  CREATE: 'create_question',
  UPDATE: 'update_question',
  INACTIVATE: 'inactivate_question',
}

const FORM_OPTIONS = {
  LIST: 'list_select_options',
  CREATE: 'create_select_option',
  EDIT: 'update_select_option',
  DESTROY: 'destroy_select_option',
}

const LEGAL_FRAMEWORKS = {
  LIST: 'list_legal_frameworks',
  EDIT: 'update_legal_framework',
}

const ANPD_CONTACTS = {
  LIST: 'list_anpd_contacts',
  CREATE: 'create_anpd_contact',
  EDIT: 'edit_anpd_contact',
  DESTROY: 'destroy_anpd_contact',
}

const DOCUMENTATION_LINKS = {
  LIST: 'list_documentation_links',
  CREATE: 'create_documentation_link',
  EDIT: 'edit_documentation_link',
  DESTROY: 'destroy_documentation_link',
}

const GROUPS = {
  LIST: 'list_groups',
  CREATE: 'create_group',
  EDIT: 'edit_group',
  DESTROY: 'destroy_group',
  ACTIVATE_OR_INACTIVATE: 'change_status_group',
}

const SUBGROUPS = {
  LIST: 'list_subgroups',
  CREATE: 'create_subgroup',
  EDIT: 'edit_subgroup',
  DESTROY: 'destroy_subgroup',
  ACTIVATE_OR_INACTIVATE: 'change_status_subgroup',
}

const SEGMENTS = {
  LIST: 'list_segments',
  CREATE: 'create_segment',
  EDIT: 'edit_segment',
  DESTROY: 'destroy_segment',
  ACTIVATE_OR_INACTIVATE: 'change_status_segment',
}

const COMPANY_SIZES = {
  LIST: 'list_company_sizes',
  CREATE: 'create_company_size',
  EDIT: 'edit_company_size',
  DESTROY: 'destroy_company_size',
  ACTIVATE_OR_INACTIVATE: 'change_status_company_size',
}

const ECONOMIC_ACTIVITIES = {
  LIST: 'list_economic_activities',
  CREATE: 'create_economic_activity',
  EDIT: 'edit_economic_activity',
  DESTROY: 'destroy_economic_activity',
  ACTIVATE_OR_INACTIVATE: 'change_status_economic_activity',
}

const PERMISSIONS_PRIVATE_DOCUMENTS_LABEL = {
  LIST_PRIVATE_DOCUMENT: 'Ver Documentos Privados',
}

const PERMISSIONS_DEFAULT_LABEL = {
  LIST: 'Listar',
  CREATE: 'Criar',
  EDIT: 'Editar',
  DESTROY: 'Deletar',
  ACTIVATE_OR_INACTIVATE: 'Ativar/Inativar',
  SHOW: 'Exibir',
  MANAGE: 'Gerenciar',
  VISUALIZE: 'Visualizar',
  MANAGE_PAYMENT: 'Gerenciar pagamento',
  INVOICE: 'Faturar contrato',
}

const PERMISSIONS_INCIDENTS_LABEL = {
  INACTIVATE: 'Inativar',
}

const PLANS = {
  LIST: 'list_plans',
  CREATE: 'create_plan',
  DESTROY: 'destroy_plan',
}

const PRE_REGISTRATIONS = {
  CREATE: 'create_backoffice_pre_registration',
  LIST: 'list_pre_registrations',
  DESTROY: 'destroy_pre_registration',
}

const PAYMENT_CONTRACTS = {
  MANAGE: 'create_payment_contract',
  LIST: 'list_payment_contracts',
  SHOW: 'show_payment_contract',
  INVOICE: 'invoice_payment_contract',
  DESTROY: 'destroy_payment_contract',
}

const PAYMENT_ORDERS = {
  MANAGE_PAYMENT: 'create_payment_order',
  LIST: 'list_payment_orders',
}

const REPORTS = {
  ACTIVATION: 'pbi_activation_report',
  SEAL: 'pbi_seal_report',
  COMPANY_QUERY: 'pbi_company_query_report',
  TICKET: 'pbi_ticket_report',
}

const DOCUMENTS = {
  LIST: 'list_documents',
  CREATE: 'create_document',
  EDIT: 'update_document',
  DESTROY: 'destroy_document',
  CATEGORY_LIST: 'list_document_categories',
  LIST_PRIVATE_DOCUMENTS: 'list_private_documents',
}

const LAST_UPDATES = {
  LIST: 'list_last_updates',
  SHOW: 'show_last_update',
  CREATE: 'create_last_update',
  EDIT: 'edit_last_update',
  DESTROY: 'destroy_last_update',
}

const FAQS_DOCUMENTATIONS = {
  CREATE: 'create_faqs_documentation',
  EDIT: 'edit_faqs_documentation',
  DESTROY: 'destroy_faqs_documentation',
}

const DOCUMENTS_TEMPLATE = {
  LIST: 'list_documents_template',
  CREATE: 'create_document_template',
  EDIT: 'update_document_template',
  DESTROY: 'destroy_document_template',
  CATEGORY_LIST: 'list_document_categories',
}

const TRANSLATED_CLIENT_PERMISSIONS_LIST = {
  departmentList: 'Departamentos',
  userList: 'Usuários',
  incidentList: 'Incidentes',
  dataProcessList: 'Processos',
  nonComplianceReportList: 'Registros de melhoria contínua',
  questionnaireList: 'Questionários',
  profileList: 'Perfis',
  ripdList: 'Relatório de Impacto à Proteção de Dados',
  dashboardList: 'Minha LGPD',
  companyList: 'Dados cadastrais da empresa',
  privacyPolicyList: 'Políticas de Privacidade',
  documentTemplateList: 'Modelos de Documento',
  diagnosticsList: 'Diagnóstico',
}

const TRANSLATED_COLLABORATOR_PERMISSIONS_LIST = {
  anpdContactList: 'Contatos ANPD',
  clientProfileList: 'Perfis de Usuários',
  collaboratorList: 'Colaboradores',
  collaboratorProfileList: 'Perfis de Colaboradores',
  companyList: 'Empresas',
  companySizeList: 'Tamanho da Empresa',
  couponList: 'Cupons',
  companySupplierList: 'Fornecedores',
  dataProcessList: 'Processos',
  departmentList: 'Departamentos',
  documentationLinkList: 'Links de Ajuda',
  economicActivityList: 'Atividades Econômicas',
  exportInfoList: 'Exportar Informações',
  exportRipdList: 'Exportar RIPD',
  formOptionList: 'Opções de Formulários',
  groupList: 'Grupos',
  incidentList: 'Incidentes',
  legalFrameworkList: 'Enquadramento Legal',
  planList: 'Planos',
  preRegistrationList: 'Clientes e sublicenciamentos',
  privacyPolicyList: 'Políticas de Privacidade',
  questionnaireByCompanyList: 'Questionários por Empresa',
  reportList: 'Relatórios',
  rmcListingList: 'Listagem de RMC',
  rmcResumeList: 'Resumo de RMC',
  segmentList: 'Segmentos',
  subgroupList: 'Subgrupos',
  userList: 'Usuários',
  paymentContractList: 'Contratos de Pagamento',
  paymentOrderList: 'Ordens de Pagamento',
  sellerList: 'Vendedores & Parceiros',
  churnList: 'Churn',
}

const DIAGNOSTICS = {
  UPDATE: 'update_question_control',
  TAKE_RISK: 'take_risk_question_control',
  OPEN_WRITE: 'open_write_question_control',
}

const SELLERS = {
  CREATE: 'create_seller',
}

const CHURNS = {
  MANAGE: 'create_churn',
  LIST: 'list_churn',
  SHOW: 'show_churn',
}

const permissions = {
  COMPANIES,
  DATA_PROCESSES,
  NON_COMPLIANCE_REPORT,
  INCIDENTS,
  USERS,
  COLLABORATORS,
  CLIENT_PROFILES,
  COLLABORATOR_PROFILES,
  COUPONS,
  DEPARTMENT,
  QUESTIONNAIRES,
  RIPD,
  DASHBOARD,
  EXPORT_INFO,
  EXPORT_RIPD,
  PRIVACY_POLICY,
  QUESTIONNAIRE_BY_COMPANY,
  QUESTION,
  FORM_OPTIONS,
  LEGAL_FRAMEWORKS,
  ANPD_CONTACTS,
  DOCUMENTATION_LINKS,
  GROUPS,
  SUBGROUPS,
  SEGMENTS,
  COMPANY_SIZES,
  ECONOMIC_ACTIVITIES,
  PERMISSIONS_DEFAULT_LABEL,
  PERMISSIONS_INCIDENTS_LABEL,
  PERMISSIONS_PRIVATE_DOCUMENTS_LABEL,
  PLANS,
  PRE_REGISTRATIONS,
  COMPANY_SUPPLIERS,
  REPORTS,
  DOCUMENTS,
  LAST_UPDATES,
  FAQS_DOCUMENTATIONS,
  DOCUMENTS_TEMPLATE,
  TRANSLATED_CLIENT_PERMISSIONS_LIST,
  TRANSLATED_COLLABORATOR_PERMISSIONS_LIST,
  DIAGNOSTICS,
  PAYMENT_CONTRACTS,
  PAYMENT_ORDERS,
  SELLERS,
  CHURNS,
}

export default permissions
